// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('@rails/activestorage').start();

require("jquery");
require("bootstrap");
require("popper.js");
require('./custom');
// require('@fortawesome/fontawesome-free');
require('./owl.carousel.min');
require('./jquery.nice-select.min');
require('./fastclick');
require('./summernote-bs4');
require('./datatables.min');
require('./custom-script');
require('./return-to-top');
require('./lightbox.min');
require('./lightbox-plus-jquery.min');
require('./slick.min');
require('./bootstrap-datepicker');
require('./bootstrap-datetimepicker');
require('./jquery.sticky');
require("@fancyapps/fancybox");


require('jquery-ui-bundle');
import 'jquery-ui-bundle/jquery-ui.min.css'

global.$ = jQuery;
global.toastr = require('toastr');


import * as FilePond from "filepond";
window.FilePond = FilePond;

import FilePondPluginFileEncode from "filepond-plugin-file-encode";
window.FilePondPluginFileEncode = FilePondPluginFileEncode;

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
window.FilePondPluginImagePreview = FilePondPluginImagePreview;

import FilePondPluginFilePoster from "filepond-plugin-file-poster";
window.FilePondPluginFilePoster = FilePondPluginFilePoster;


FilePond.registerPlugin(
    FilePondPluginFileEncode,
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginFilePoster
);

const Swal = require('sweetalert2');
window.Swal = Swal;
// const images = require.context('../images', true);
// const imagePath = (name) => images(name, true);
import  "../stylesheets/application.scss";

import {init_filepond_single, init_filepond_multiple} from './custom'


window.init_filepond_single = init_filepond_single;
window.init_filepond_multiple = init_filepond_multiple;
