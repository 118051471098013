$(document).ready(function () {

    if ($(window).width() > 992 && $("#sticker").length) {
        var stickyBottomOffset = $('footer').height();
        if ($('#show-similar').height() > 100)
            $("#sticker").sticky({topSpacing: 100, responsiveWidth: true, bottomSpacing: stickyBottomOffset + $('#sticker').height() + $('nav').height()});
        else {
            $("#sticker").sticky({topSpacing: 100, responsiveWidth: true, bottomSpacing: stickyBottomOffset + $('nav').height()});
        }
    }

    $(".enq-date").change(function () {
        $(".enquiry-date").val($(this).val())
    });

    $(".enquiry-date").datepicker({
        dateFormat: 'dd/mm/yy',
    });
    $("#eventenquiries_date").datepicker({
        dateFormat: 'dd/mm/yy',
        showOn: ''
    });

    $("#eventenquiries_date").click(function(){
        $("#eventenquiries_date").datepicker('show');
    });

    $(".enquiry-time").datetimepicker({
        pickDate: false,
        minuteStep: 15,
        pickerPosition: 'bottom-right',
        format: 'HH:ii p',
        autoclose: true,
        showMeridian: true,
        startView: 1,
        maxView: 1,
    });

    $(".enquiry-time").find('thead th').remove();
    $(".enquiry-time").find('thead').append($('<th class="switch">').text('Pick Time'));
    $('.switch').css('width','190px');



    $("#btnPrvStep1").click(function () {
        $("#step2").addClass('d-none');
        $("#step1").removeClass('d-none');
    });
    $("#btnPrvStep2").click(function () {
        $("#step3").addClass('d-none');
        $("#step2").removeClass('d-none');
    });
    $("#btnPrvStep3").click(function () {
        $("#step4").addClass('d-none');
        $("#step3").removeClass('d-none');
    });
    $("#btnEndStep1, .btnEndStep2").click(function () {
        $("#step1").addClass('d-none');
        $("#step2").removeClass('d-none');
    });
    $("#btnEndStep2").click(function () {
        $("#step2").addClass('d-none');
        $("#step3").removeClass('d-none');
    });
    $("#btnEndStep3").click(function () {
        $("#step3").addClass('d-none');
        $("#step4").removeClass('d-none');
    });
    $("#btnEndStep4").click(function () {
        $("#event_enquiry_form").submit();
    });


    $('.gift-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        touchMove: true,
        speed: 500,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px'
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px'
                }
            }
        ]
    });

    $('.show-gift-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        touchMove: true,
        speed: 500,
        dots: false,
        asNavFor: '.gift-slider-nav'
    });

    $('.gift-slider-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 3,
        asNavFor: '.show-gift-slider',
        arrows: false,
        infinite: false,
        focusOnSelect: true
    });

    $('.list-slider-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 3,
        asNavFor: '.show-list-slider',
        arrows: false,
        infinite: false,
        focusOnSelect: true
    });

    $('.show-list-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        touchMove: true,
        speed: 500,
        dots: false,
        asNavFor: '.list-slider-nav'
    });

    $('.offer-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        touchMove: true,
        speed: 500,
        dots: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px'
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false,
                    arrows: false,
                    centerMode: true,
                    centerPadding: '80px'
                }
            }
        ]
    });
    $('[data-toggle="tooltip"]').tooltip();

    if($('.wedding-slider-show').length > 0) {
        $('.wedding-slider-show').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            touchMove: true,
            speed: 500,
            autoplaySpeed: 3000,
            arrows: true,
            infinite: false,
            dots: false,
            asNavFor: '.wedding-slider-nav'
        });
        $('.wedding-slider-nav').slick({
            slidesToShow: 4,
            slidesToScroll: 3,
            asNavFor: '.wedding-slider-show',
            arrows: false,
            infinite: false,
            focusOnSelect: true
        });
    }

    $(window).scroll(function(){
        var sticky = $('.home-nav'),
            scroll = $(window).scrollTop();

        if (scroll >= 100) sticky.addClass('fixed');
        else sticky.removeClass('fixed');
    });


    init_filepond_single();
    init_filepond_multiple();
});

$(document).ready(function () {

    $('#category_datatable,#blog_listing_datatable').DataTable({
        searching: true,
        lengthChange: true,
        pageLength: 10,
    });

    if ($('select').length) {
        // $(document).ready(function () {
        $('select').niceSelect();
        //FastClick.attach(document.body);
        // });
    }

    $(".date_chosen").datepicker({
        format: "yyyy-mm-dd"
    });


    // if (
    //     $(
    //         '.owl-venue-thumb, .owl-venue-thumb-second, .owl-second, .owl-slider, .post-owl, .owl-carousel'
    //     ).length
    // ) {
    //     $('.owl-venue-thumb').owlCarousel({
    //         loop: true,
    //         margin: 0,
    //         autoplay: true,
    //         autoplayTimeout: 3000,
    //         Item_Width: 100,
    //         nav: true,
    //         navText: [
    //             '<i class="fa fa-angle-left"></i>',
    //             '<i class="fa fa-angle-right"></i>',
    //         ],
    //         responsive: {
    //             0: {
    //                 items: 1,
    //             },
    //             600: {
    //                 items: 2,
    //             },
    //             1000: {
    //                 items: 4,
    //             },
    //             1200: {
    //                 items: 5,
    //             },
    //             1400: {
    //                 items: 5,
    //             },
    //             1600: {
    //                 items: 5,
    //             },
    //             1800: {
    //                 items: 5,
    //             },
    //         },
    //     });
    //     $('.owl-venue-thumb-second').owlCarousel({
    //         loop: true,
    //         margin: 0,
    //         autoplay: true,
    //         autoplayTimeout: 3000,
    //         Item_Width: 100,
    //         nav: true,
    //         navText: [
    //             '<i class="fa fa-angle-left"></i>',
    //             '<i class="fa fa-angle-right"></i>',
    //         ],
    //         responsive: {
    //             0: {
    //                 items: 1,
    //             },
    //             600: {
    //                 items: 2,
    //             },
    //             1000: {
    //                 items: 4,
    //             },
    //             1200: {
    //                 items: 3,
    //             },
    //             1400: {
    //                 items: 3,
    //             },
    //             1600: {
    //                 items: 3,
    //             },
    //             1800: {
    //                 items: 3,
    //             },
    //         },
    //     });
    //
    //     $('.owl-second').owlCarousel({
    //         loop: true,
    //         margin: 5,
    //         autoplay: true,
    //         autoplayTimeout: 3000,
    //         nav: true,
    //         navText: [
    //             '<i class="fa fa-angle-left"></i>',
    //             '<i class="fa fa-angle-right"></i>',
    //         ],
    //         responsive: {
    //             0: {
    //                 items: 1,
    //             },
    //             600: {
    //                 items: 2,
    //             },
    //             1000: {
    //                 items: 4,
    //             },
    //         },
    //     });
    //
    //     $('.owl-slider').owlCarousel({
    //         loop: true,
    //         margin: 0,
    //         autoplay: true,
    //         autoplayTimeout: 3000,
    //         nav: true,
    //         navText: [
    //             '<i class="fa fa-angle-left"></i>',
    //             '<i class="fa fa-angle-right"></i>',
    //         ],
    //         responsive: {
    //             0: {
    //                 items: 1,
    //             },
    //             600: {
    //                 items: 1,
    //             },
    //             1000: {
    //                 items: 1,
    //             },
    //         },
    //     });
    //
    //     $('.post-owl').owlCarousel({
    //         loop: true,
    //         margin: 5,
    //         autoplay: true,
    //         autoplayTimeout: 3000,
    //         nav: true,
    //         navText: [
    //             '<i class="fa fa-angle-left"></i>',
    //             '<i class="fa fa-angle-right"></i>',
    //         ],
    //         responsive: {
    //             0: {
    //                 items: 1,
    //             },
    //             600: {
    //                 items: 1,
    //             },
    //             1000: {
    //                 items: 1,
    //             },
    //         },
    //     });
    //
    //     $('.owl-carousel').owlCarousel({
    //         loop:true,
    //         margin:10,
    //         items: 3,
    //         //nav:true //,
    //         // center:true,
    //         responsive:{
    //             0:{
    //                 items:1,
    //
    //             },
    //             1080:{
    //                 items:2
    //             },
    //             1650:{
    //                 items:3
    //             }
    //         }
    //     })
    // }

});

export function init_filepond_single(){

    $(".filepond-single").each(function() {
        if ($(this).data("name")) {
            createPoster(
                this,
                $(this).data("name"),
                $(this).data("size"),
                $(this).data("type"),
                $(this).data("url")
            );
        } else {
            FilePond.create(this, {
                name: "filepond"
            });
        }
    });
}

function createPoster(input, name, size, type, url) {
    FilePond.create(input, {
        name: "filepond",
        imagePreviewHeight: 200,
        filePosterMaxHeight: 200,
        files: [{
            options: {
                file: {
                    name: name,
                    size: size,
                    type: type,
                },
                metadata: {
                    poster: url,
                },
            },
        }]
    });
}

export function init_filepond_multiple(){
    $(".filepound-multiple").each(function() {
        createMultiplePoster(
            this,
            $(this).data("files"),
            $(this).data("remove-url")
        );
    });
}

function createMultiplePoster(input, files, delete_url) {
    FilePond.create(input, {
        name: "filepond",
        files: files,
        imagePreviewHeight: 100,
        filePosterMaxHeight: 100
    });

    $(".filepound-multiple").on("FilePond:removefile", function(e) {
        var id = e.detail.file.file["id"];
        var img_id = e.detail.file.file["img_id"];
        if(id){
            $.ajax({
                url: delete_url,
                type: "POST",
                datatype: "Script",
                data: { img_id: img_id, id: id },
                success: function(result) {
                }
            });
        }
    });
}